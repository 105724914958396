document.addEventListener("DOMContentLoaded", function () {
    const { pathname } = window.location;
    const liElements = document.querySelectorAll(".has-wlc-mega-menu");

    liElements.forEach((li) => {
        const links = li.querySelectorAll(".wlc-mega-menu a");
        const menuArrow = li.querySelector(".wlc-mega-menu__arrow");

        let isInMenu = false;
        links.forEach((item) => {
            const href = item.getAttribute("href");
            if (href && href.endsWith(pathname) && pathname.length > 2) {
                const liParent = item.closest(".wp-block-navigation-item");
                isInMenu = true;
                liParent.classList.add("current-menu-item");
            }
        });

        // Add active class to mega menu on arrow click
        menuArrow.addEventListener("click", function () {
            this.classList.toggle("wlc-mega-menu__arrow--active");
        });

        if (isInMenu) {
            li.classList.add("current-menu-item");
        }

        li.addEventListener("mouseenter", function () {
            this.classList.add("wlc-mega-menu--open");
        });

        li.addEventListener("mouseleave", function () {
            this.classList.remove("wlc-mega-menu--open");
        });
    });
});

document.addEventListener("DOMContentLoaded", function () {
    let toggleButtons = document.querySelectorAll(".wlc-mega-menu__toggle-btn");
    let megaMenuDivs = document.querySelectorAll(".wlc-mega-menu");

    megaMenuDivs.forEach((megaMenuDiv) => {
        // Add 'Back' btn as a first element in .wlc-mega-menu
        let backButton = document.createElement("button");
        backButton.textContent = "Back";
        backButton.classList.add("wlc-mega-menu__back-btn", "mobile-only");
        megaMenuDiv.insertBefore(backButton, megaMenuDiv.firstChild);

        //  Add click event to 'back' btn
        backButton.addEventListener("click", function () {
            megaMenuDiv.classList.remove("wlc-mega-menu--active");

            //  Find arrow btn and change its state
            let arrowButton = megaMenuDiv.parentElement.querySelector(
                ".wlc-mega-menu__toggle-btn"
            );
            if (arrowButton) {
                arrowButton.setAttribute("data-state", "closed");
            }
        });
    });

    toggleButtons.forEach((button) => {
        button.addEventListener("click", function () {
            let state = button.getAttribute("data-state");
            let megaMenuDiv = button
                .closest(".has-wlc-mega-menu")
                .querySelector(".wlc-mega-menu");

            if (state === "closed") {
                button.setAttribute("data-state", "opened");
                if (megaMenuDiv) {
                    megaMenuDiv.classList.add("wlc-mega-menu--active");
                }
            } else {
                button.setAttribute("data-state", "closed");
                if (megaMenuDiv) {
                    megaMenuDiv.classList.remove("wlc-mega-menu--active");
                }
            }
        });
    });
});

// Closing modal on resize
const htmlElement = document.documentElement;
const navigationContainer = document.querySelector(
    ".wp-block-navigation__responsive-container"
);

function isMenuOpen() {
    return htmlElement.classList.contains("has-modal-open");
}

function closeMenu() {
    htmlElement.classList.remove("has-modal-open");
    navigationContainer.classList.remove("has-modal-open","is-menu-open")
}

window.addEventListener("resize", function () {
  console.log(navigationContainer)
    if (isMenuOpen()) {
        closeMenu();
    }
});
